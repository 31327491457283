/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM957D
 * 화면 설명: 보험계약기본사항 > 미래예시 > 미래연금 : 공시이율형 연금(연금개시 이후)
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.03.15
 * 작 성 자: 서영화
 */
<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">  
      <!-- 검색조회 start -->    
      <mo-list-item class="expand_bottomline expand_topline"> 
        <!-- msp-expand 에 맨 첫번째 값만 펼침 일 겨우 첫번째에 expanded 넣어주세요! -->
        <msp-expand ref="expandMSPPM57D"
                    btn-area-first title-first expand-only-btn class="mo-list-expand" btn-area-class="fexTy3 align-item-start">
          <template #title>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <span class="name fs19rem">예시기준</span>
              </div>
            </div>
          </template>
          <template #btn>
              <mo-button class="link-arrow down"></mo-button>
          </template>
          <template #content>
            <div class="full-list mt20 mbm20">
              <div class="list-item__contents gray-box pa2024">
                <div class="list-item__contents__info fexTy5">
                  <span class="min120 crTy-bk7 ">연금개시 준비금</span>
                  <span class="ml20 fs16rem">{{ anutInsrBasTypVO.prcAnutBgnPrsv }} 원</span>
                </div>
                <div v-if="!anutInsrBasTypVO.anutPymTypCdKraNm01.includes('상속')" class="list-item__contents__info fexTy5 mt4">
                  <template>
                    <span class="min120 crTy-bk7" >종신/확정 연금재원</span>
                    <!-- <span class="min120 crTy-bk7" v-else>상속 연금재원</span> -->
                  </template>
                  <span class="ml20 fs16rem">{{ anutInsrBasTypVO.prcAnutFrscAmt }}</span>
                </div>
                
                <!-- @notice : 23.05.12 인터페이스에서 잘못된 데이터 리턴되어서 해당 부분 주석처리 -->
                <!-- 
                <div class="list-item__contents__info fexTy5 mt4">
                  <span class="min120 crTy-bk7">연금전환가입금액</span>
                  <span class="ml20 fs16rem">{{ anutInsrBasTypVO.prcAnutCnvrEntAmt }} 원</span>
                </div> -->
                
                <div class="list-item__contents__info fexTy5 mt4">
                  <span class="min120 crTy-bk7">연금지급대상</span>
                  <span class="ml20 fs16rem">{{ anutInsrBasTypVO.anutPymObjScCdKraNm }}</span>
                </div>
                <div class="list-item__contents__info fexTy5 mt4">
                  <span class="min120 crTy-bk7">기준이율 유형</span>
                  <span class="ml20 fs16rem">{{ anutInsrBasTypVO.irtKndCd }}</span>
                </div>
                <div class="list-item__contents__info fexTy5 mt4">
                  <span class="min120 crTy-bk7">기준이율</span>
                  <span class="ml20 fs16rem">{{ anutInsrBasTypVO.stndIrt }}</span>
                </div>
                <div class="list-item__contents__info fexTy5 mt4">
                  <span class="min120 crTy-bk7">예시적용이율</span>
                  <span class="ml20 fs16rem">{{ anutInsrBasTypVO.aplcIrt }}</span>
                </div>
                <div class="list-item__contents__info fexTy5 mt4">
                  <span class="min120 crTy-bk7">조기연금전환</span>
                  <span class="ml20 fs16rem">{{ anutInsrBasTypVO.earlAnutPsbYn }}</span>
                </div>
                <div class="list-item__contents__info fexTy5 mt4">
                  <span class="min120 crTy-bk7">노후설계자금</span>
                  <span class="ml20 fs16rem">{{ anutInsrBasTypVO.oldagPlanFdRto }} % ({{ anutInsrBasTypVO.oldagPlanFdAmt.doubleAmount }} 원)</span>
                </div>
                <div class="list-item__contents__info fexTy5 mt4">
                  <span class="min120 crTy-bk7">연금지급형태1</span>
                  <span class="ml20 fs16rem">{{ anutInsrBasTypVO.anutPymTypCdKraNm01 }}</span>
                </div>
                <div class="list-item__contents__info fexTy5 mt4">
                  <span class="min120 crTy-bk7">연금지급형태2</span>
                  <span class="ml20 fs16rem">{{ anutInsrBasTypVO.anutPymTypCdKraNm02 }}</span>
                </div>
              </div>
            </div>
          </template>
        </msp-expand>
      </mo-list-item> 
      <!-- 검색조회 end -->

      <!-- 데이터 개수 start -->
      <ur-box-container class="area-bb flex-sc">
        <div class="txt-sum">
          총 <strong>{{ totalCount }}</strong> 건 
          <span class="fs14rem crTy-bk7 fwn">(단위:원)</span>
        </div>
        <!-- radio 버튼 영역 -->
        <div class="wrap-btn mr-m10" v-if="!isNoData">
          <mo-radio-wrapper v-model="radioButton" class="ns-radio-list2">
            <mo-radio value="1" small>목록</mo-radio>
            <mo-radio value="2" small>표</mo-radio>
          </mo-radio-wrapper>
          <mo-button icon="msp-ptmode" class="ns-btn-round black sm" @click="fn_open">크게보기</mo-button>
        </div>
      </ur-box-container>
      <!-- 데이터 개수 end -->

      <!-- 데이터 목록 start -->
      <template v-if="!isNoData">
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area pb-0">

          <!-- 표 모드 -->
          <ur-box-container v-if="radioButton==='2'" class="sticky-pd">
            <div class="sticky-area">
              <div class="table-area sticky-t02" ref="tableArea" @scroll="fn_checkScroll(1)">
                <table class="table row-type ws_N">
                  <thead>
                    <tr>
                      <th>연도</th>
                      <th>연령</th>
                      <th>연금지급</th>
                      <th>복수연금</th>
                      <th>노후설계</th>
                      <th>합계</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in anutInsrBasTypVO.bpanutOccuYmAmtVO04" :key="i">
                      <td> {{item.occuYr}} </td>
                      <td> {{item.age}} </td>
                      <td class="aR"> {{item.prcDoubleAmount01}} </td>
                      <td class="aR"> {{item.prcDoubleAmount02}} </td>
                      <td class="aR"> {{item.prcDoubleAmount03}} </td>
                      <td class="aR"> {{item.prcDoubleAmount04}} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button class="icon-right2" @click="fn_scrollToRight(1)" ref="iconRight" v-show="isShowChevron"><i></i></button>
              <button class="icon-down2 pos-fixed" @click="fn_scrollToDown(1)" ref="iconDown" v-show="isShowChevronDown"><i></i></button>
            </div>
          </ur-box-container>

          <!-- 목록 모드 -->
          <ur-box-container v-if="radioButton ==='1'" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-list">
            <mo-list :list-data="anutInsrBasTypVO.bpanutOccuYmAmtVO04">
              <template #list-item="{item}">
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__title">
                      <span class="fwn crTy-bk7 fs17rem mr10">연도</span>
                      <span class="fs19rem fwb">{{ item.occuYr }}</span>
                      <em class="align-self-center"></em>
                      <span class="fwn crTy-bk7 fs17rem mr10">연령</span>
                      <span class="fs19rem fwb">{{ item.age }} 세</span>
                    </div>
                    <div class="list-item__contents__info pr0">
                      <div class="info_box mt10">
                        <div class="full">
                          <span class="label-title mr10">연금지급</span>
                          <span>{{ item.prcDoubleAmount01 }}</span>
                          <em></em>
                          <span class="label-title mr10">복수연금</span>
                          <span>{{ item.prcDoubleAmount02 }}</span>
                        </div>
                        <div class="full">
                          <span class="label-title mr10">노후설계</span>
                          <span>{{ item.prcDoubleAmount03 }}</span>
                          <em></em>
                          <span class="label-title mr10">합계</span>
                          <span>{{ item.prcDoubleAmount04 }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </mo-list-item>
              </template>
            </mo-list>
          </ur-box-container>
        </ur-box-container>
      </template>
      <!-- 데이터 목록 end -->

      <!-- 데이터 없음 start-->
      <template v-else>
        <ur-box-container  alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data"> <!-- expand_bottomline -->
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
      </template>
      <!-- 데이터 없음 end-->

      <!-- 크게보기 모드 -->
      <mo-bottom-sheet ref="nsbottomsheet2" class="ns-bottom-sheet ptmode">
        <mo-top-bar>
          <mo-button class="btn_popup_back"></mo-button>
          <div slot="nav" class="icon-wrapper" @click="fn_close">
            <mo-icon color="white" borderless>drawer</mo-icon>
          </div>
          <div class="ui-gnb-title__main">미래연금</div>
        </mo-top-bar>
        <!-- content 영역 -->
        <div class="content-area">
          <ur-box-container alignV="start" direction="column" class="ns-list-area">
            <ur-box-container>
              <div class="sticky-area">
                <div class="table-area sticky-t02" ref="tableAreaPop" @scroll="fn_checkScroll(2)">
                  <table class="table row-type ws_N">
                    <thead>
                      <tr>
                        <th>연도</th>
                        <th>연령</th>
                        <th>연금지급</th>
                        <th>복수연금</th>
                        <th>노후설계</th>
                        <th>합계</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in anutInsrBasTypVO.bpanutOccuYmAmtVO04" :key="i">
                        <td> {{item.occuYr}} </td>
                        <td> {{item.age}} </td>
                        <td class="aR"> {{item.prcDoubleAmount01}} </td>
                        <td class="aR"> {{item.prcDoubleAmount02}} </td>
                        <td class="aR"> {{item.prcDoubleAmount03}} </td>
                        <td class="aR"> {{item.prcDoubleAmount04}} </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button class="icon-right" @click="fn_scrollToRight(2)" ref="iconRightPop" v-if="isShowChevron2"><i></i></button>
                <button class="icon-down" @click="fn_scrollToDown(2)" ref="iconDownPop" v-if="isShowChevronDown2"><i></i></button>
              </div>
            </ur-box-container>
          </ur-box-container>
        </div>
      </mo-bottom-sheet>

    </ur-box-container>

    
  </ur-page-container>
</template>

<script>

export default {

  name: 'MSPPM957D', 
  screenId: 'MSPPM957D', 
  components: {},//componets,

  props:{
    pMSPPM953DData: {
      type: Object,
      default: {}
    },
  },//props
  
  data () {
    return {
      title: '미래연금 : 공시이율형 연금(연금개시 이후)',

      /**
       * 화면 변수
       */
      isNoData: false,
      totalCount: 0,                  // 공시이율형 연금(연금개시 이후) 총 개수
      radioButton: '2',               // 라디오 버튼 선택 값
      isShowChevron: false,           // affordance 가로 버튼 노출여부
      isShowChevronDown: false,       // affordance 세로 버튼 노출여부
      isShowChevron2: false,          // 크게보기 affordance 버튼 노출여부
      isShowChevronDown2: false,      // 크게보기 affordance 버튼 노출여부
      /**
       * 조회변수
       */
      anutInsrBasTypVO: {},     // 공시이율형 연금(연금개시 이전) 데이터

    }
  },//data

  created () {

     this.fn_Init()

  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted


  watch: {},//watch

  computed: {},//computed

  methods: {
     
   /**
    * @description 초기화한다.
    */
   fn_Init(){

      // 초기화 
      this.anutInsrBasTypVO = {}
      this.totalCount       = 0
      this.isNoData         = false

      // 데이터 세팅 
      this.anutInsrBasTypVO = this.pMSPPM953DData  // MSPPM953D 미래연금 데이터  

      // 예시기준 데이터 가공
      this.anutInsrBasTypVO.prcAnutBgnPrsv    = this.$commonUtil.numberWithCommas(this.anutInsrBasTypVO.anutBgnPrsv.doubleAmount)    // 연금개시시준비금
      this.anutInsrBasTypVO.prcAnutCnvrEntAmt = this.$commonUtil.numberWithCommas(this.anutInsrBasTypVO.anutCnvrEntAmt.doubleAmount) // 연금전환가입금액
      this.anutInsrBasTypVO.prcAnutFrscAmt    = this.$commonUtil.numberWithCommas(this.anutInsrBasTypVO.anutFrscAmt.doubleAmount)    // 종신/확정 연금재원
      this.anutInsrBasTypVO.stndIrt           = Number(this.anutInsrBasTypVO.stndIrt)/1000 + " %"                                    // 기준이율
      this.anutInsrBasTypVO.aplcIrt           = Number(this.anutInsrBasTypVO.aplcIrt)/1000 + " %"                                    // 예시적용이율
      this.anutInsrBasTypVO.earlAnutPsbYn     = (this.anutInsrBasTypVO.earlAnutPsbYn === 'Y') ? '가능' : '불가능'                     // 조기연금예시 여부 

      // 목록 데이터 가공 
      let listData = this.anutInsrBasTypVO.bpanutOccuYmAmtVO04  // 목록데이터

      if(this.$bizUtil.isEmpty(listData) || listData.length === 0){
        this.isNoData = true 
      }else{
        this.totalCount = listData.length // 미래연금(연금보험 기본형:개시후) 목록 데이터 

        listData.forEach((item) => {
          item.prcDoubleAmount01 = this.$commonUtil.numberWithCommas(item.anpsn01.doubleAmount) // 연금지급유형
          item.prcDoubleAmount02 = this.$commonUtil.numberWithCommas(item.anpsn02.doubleAmount) // 복수연금유형
          item.prcDoubleAmount03 = this.$commonUtil.numberWithCommas(item.anpsn03.doubleAmount) // 노후설계(내리사랑)
          item.prcDoubleAmount04 = this.$commonUtil.numberWithCommas(item.anpsn04.doubleAmount) // 합계
        })
      }

      // affordance 버튼 제어(Y축 스크롤 생길 경우만 down 버튼 노출)
      this.$nextTick(() => {
        if(this.anutInsrBasTypVO.bpanutOccuYmAmtVO04.length > 0){
          this.isShowChevronDown = true
          this.isShowChevron = true
        } else {
          this.isShowChevronDown = false
          this.isShowChevron = false
        }
      })

   },

   /**
    * @description 크게보기 오픈.
    */
    fn_open() {
      this.$refs.nsbottomsheet2.open();

      // affordance 버튼 제어
      this.$nextTick(() => {
        if(this.anutInsrBasTypVO.bpanutOccuYmAmtVO04.length > 0){
          this.isShowChevronDown2 = true
          this.isShowChevron2 = true
        }else{
          this.isShowChevronDown2 = false
          this.isShowChevron2 = false
        }
      })
    },

   /**
    * @description 크게보기 모드 닫기.
    */
    fn_close() {
      this.$refs.nsbottomsheet2.close();
    },

   /**
    * @description 표 모드 스크롤 이벤트.
    */
    fn_checkScroll(value) {
      if(value === 1) {       // 표 모드
        if(this.$refs.tableArea.scrollLeft > 10) {
          this.isShowChevron = false
          this.isShowChevronDown = false
        }
      }
      else {        // 크게보기 모드
        if(this.$refs.tableAreaPop.scrollLeft > 10 || this.$refs.tableAreaPop.scrollTop > 10 ) { 
          this.isShowChevron2 = false
          this.isShowChevronDown2 = false
        }
      }
    },

   /**
    * @description Right affordance 버튼 이벤트.
    */
    fn_scrollToRight(value) {
      let tableArea
      if(value === 1) {
        this.isShowChevron = false
        this.isShowChevronDown = false
        tableArea = this.$refs.tableArea;
      } else {
        this.isShowChevron2 = false
        this.isShowChevronDown2 = false
        tableArea = this.$refs.tableAreaPop;
      }
      const scrollAmount = 200;
      const scrollDuration = 1000;
      let startingPosition = tableArea.scrollLeft;
      let targetPosition = startingPosition + scrollAmount;
      this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'horizontal');
     },

   /**
    * @description Down affordance 버튼 이벤트.
    */
    fn_scrollToDown(value) {
      if(value === 1) {                 // 표 모드
        this.isShowChevron = false
        this.isShowChevronDown = false
        this.$emit('scrollDown')
      } else {                          // 크게보기 모드
        this.isShowChevron2 = false
        this.isShowChevronDown2 = false
        const tableArea = this.$refs.tableAreaPop
        const scrollAmount = 200
        const scrollDuration = 1000
        const startingPosition = tableArea.scrollTop;
        const targetPosition = startingPosition + scrollAmount;
        this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'vertical');
      }
    },

   /**
    *  @description 표에 있는 affordance 버튼 제어
    */
    fn_ctrAffordance() {
      this.isShowChevron = false
      this.isShowChevronDown = false
    },
    
    animateScroll(element, start, target, duration, direction) {
      const startTime = performance.now();
      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const easedProgress = this.easeInOutCubic(progress);
        if (direction === 'vertical') {
          element.scrollTop = start + (target - start) * easedProgress;
        } else if (direction === 'horizontal') {
          element.scrollLeft = start + (target - start) * easedProgress;
        }
        if (elapsedTime < duration) {
          requestAnimationFrame(animate);
          
        }
      };
      animate(startTime);
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t -2) + 1;
    }
 },// methods

}//export default


</script>