/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM953D
 * 화면 설명: 보험계약기본사항 > 미래예시 > 미래연금
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.03.07
 * 작 성 자: 서영화
 */
<template>
  <ur-page-container title="미래연금" :show-title="false" type="subpage">

    <template v-if="!isNoData">

      <!-- 연금보험 기본형(개시전) -->   
      <keep-alive>
        <component :is="targetScreenNm" v-if="targetScreen === '1'" :ref="targetScreenNm" :pIsSearched="isSearched" :pMSPPM953DData="fueAnutVO" @condSumbit="fn_CondSubmit" @scrollDown="fn_scrollDown"/>                            
      </keep-alive>

      <!-- 연금보험 기본형(개시후) -->   
      <MSPPM955D v-if="targetScreen === '2'" :ref="targetScreenNm" :pMSPPM953DData="fueAnutVO" @scrollDown="fn_scrollDown"/>                            
      
      <!-- 공시이율형 연금(연금개시 이전) -->   
      <keep-alive>
        <component :is="targetScreenNm" v-if="targetScreen === '3'" :ref="targetScreenNm" :pIsSearched="isSearched" :pMSPPM953DData="fueAnutVO" @condSumbit="fn_CondSubmit" @scrollDown="fn_scrollDown"/>                            
      </keep-alive>
      
      <!-- 공시이율형 연금(연금개시 이후) -->
      <MSPPM957D v-if="targetScreen === '4'" :ref="targetScreenNm" :pMSPPM953DData="fueAnutVO" @scrollDown="fn_scrollDown"/>           
      
      <!-- 미래연금 : 변액연금(연금개시 이전) -->
      <keep-alive>
        <component :is="targetScreenNm" v-if="targetScreen === '5'" :ref="targetScreenNm" :pIsSearched="isSearched" :pMSPPM953DData="fueAnutVO" @condSumbit="fn_CondSubmit" @scrollDown="fn_scrollDown"/>                            
      </keep-alive>
      
      <!-- 미래연금 : 변액연금(연금개시 이후) -->
      <MSPPM959D v-if="targetScreen === '6'" :ref="targetScreenNm" :pMSPPM953DData="fueAnutVO" @scrollDown="fn_scrollDown"/>       

    </template>

    <!-- 데이터 없음 -->
    <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data">
      <mo-list-item>
        <div class="list-item__contents">
          <div class="list-item__contents__info">
            <span>데이터가 없습니다.</span>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>
    
    <!-- bottomSheet -->
    <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet">
      <div class="ns-btn-close"></div>
      <!-- content 영역 -->
      <div class="customer-info-txt txt-center">
        <p class="ment">{{ serviceMsg }}</p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button @click="fn_CloseBottomSheet" componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" name="확인" >확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

  </ur-page-container>
</template>

<script>

import MSPPM954D from '@/ui/pm/MSPPM954D.vue' // 미래예시 >  미래연금 : 연금보험 기본형(개시전)
import MSPPM955D from '@/ui/pm/MSPPM955D.vue' // 미래예시 >  미래연금 : 연금보험 기본형(개시후)
import MSPPM956D from '@/ui/pm/MSPPM956D.vue' // 미래예시 >  미래연금 : 공시이율형 연금(연금개시 이전)
import MSPPM957D from '@/ui/pm/MSPPM957D.vue' // 미래예시 >  미래연금 : 공시이율형 연금(연금개시 이후)
import MSPPM958D from '@/ui/pm/MSPPM958D.vue' // 미래예시 >  미래연금 : 변액연금(연금개시 이전)
import MSPPM959D from '@/ui/pm/MSPPM959D.vue' // 미래예시 >  미래연금 : 변액연금(연금개시 이후)

export default {

  name: 'MSPPM953D', 
  screenId: 'MSPPM953D', 
  components: { 
    MSPPM954D, MSPPM955D,  
    MSPPM956D, MSPPM957D,
    MSPPM958D, MSPPM959D
  },//componets,

  props:{},//props

  data () {
    return {
      title: '미래연금',

      /**
       * @notice MSPPM954D, MSPPM956D, MSPPM958D 예시기준 값들은 조회 후에도 고정되어야 해야돼서 keep alive 사용
       */

      /**
       * pmStore 및 props 변수 
       */
      contBasMatrVO: {},    // 계약메인데이터(부모데이터) 
      contNo: '',           // 계약번호 

      lv_InputParam: {},    // 서비스호출 파라미터
      
      /**
       * 화면 변수
       */ 
      targetScreen: '',     // 노출되는 화면  
      targetScreenNm: '',   
      isNoData: false,      
      isSearched: false,    // 검색조건 조회 여부 
      serviceMsg: '',       // 서비스 실행 후 출력되는 메시지 
      ctrYn: '',            // 데이터가 조회된 경우만 affordance 컨트롤
      
      /**
       * 조회 변수
       */ 
      fueAnutVO:{},          // 미래연금 데이터 

    }
  },//data

  created () {

    
  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  watch: {},//watch

  computed: {},//computed

  methods: {
    
    /**
     * @description 뒤로가기를 한다.
     */
    fn_BackToPrevious(){

      if(this.isSearched){
        this.fn_Submit(false)
      }else{
        this.$router.go(-1)
      }

    },

   /**
    * @description 선택한 예시기준으로 검색을 한다.
    * @param       {Object} data 선택된 예시기준 데이터 
    */                
    fn_CondSubmit(data){

      this.fn_Submit(true, data)
    
    },

   /**
     * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
     * @param       {Boolean} flag     false: 최초 조회, true: 검색 조회 
     * @param       {Object}  condInfo 검색조회 조건 데이터 
     */
    fn_Submit(flag, condInfo){

      // 초기화 
      this.fueAnutVO      = {}
      this.targetScreen   = ''
      this.isNoData       = false 
      this.serviceMsg     = ''

      // 데이터 세팅 
      this.contBasMatrVO  = this.getStore('pmStore').getters.getState.contInfo   // MSPPM931M 계약 데이터 
      this.contNo         = this.contBasMatrVO.contNo

      this.lv_InputParam  = {}

      if(!flag){
        this.isSearched = false 
        this.lv_InputParam.contNo       = this.contNo
        this.lv_InputParam.fnctScCd     = 'S1'
        this.lv_InputParam.retPrscpScCd = '1'
      }else{
        this.isSearched = true 
        this.lv_InputParam              = condInfo 
        this.lv_InputParam.contNo       = this.contNo
        this.lv_InputParam.fnctScCd     = 'S3'
      }

      // 서비스 호출 
      this.fn_GetService()
      
    },
    
   /**
     * @description 미래연금 목록 조회 서비스 호출한다.
     * @callback    fn_HandleCallback
     */
    fn_GetService() {

      const auth = 'S'
      const trnstId = this.$pmUtil.getTrnstId()

      let pParams = this.lv_InputParam
      let commHeader = {
        eaiId: this.$pmUtil.getSVCId('F1FPO0022'),
        fahrTrnId: this.lv_InputParam.fnctScCd,
        requestSystemCode: '',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: '',
        reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPO0022VO',
        resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPO0022VO'
      }

      this.$pmUtil.eaiInvoke(pParams, trnstId, auth, commHeader, this.fn_HandleCallback)
    
    },
    
   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      // 인터페이스 오류시 confirm 창 띄우고 return
      if (!response.body) {

        let msgcomm = response.msgComm
        let message = '[' + msgcomm.msgCd + '] ' + msgcomm.msgDesc

        // 데이터 없음
        this.isNoData   = true 
        this.serviceMsg = msgcomm.msgDesc
        this.$refs.nsbottomsheet.open()

        return
      }

      let fahrTrnId     = response.trnstComm.commHeaderVO.fahrTrnId     
      this.fueAnutVO    = response.body                            
      this.targetScreen = this.fueAnutVO.prdtScCd                   
      

      this.ctrYn = true

      this.$nextTick(() => {

        switch(this.targetScreen){
          case '1': 
            this.targetScreenNm = 'MSPPM954D' 
            // 검색으로 조회할 경우
            if (fahrTrnId === 'S3') this.$refs.MSPPM954D.fn_SetCondInfo()
            break
          case '2': 
            this.targetScreenNm = 'MSPPM955D'
            break
          case '3': 
            this.targetScreenNm = 'MSPPM956D'
            // 검색으로 조회할 경우
            if (fahrTrnId === 'S3') this.$refs.MSPPM956D.fn_SetCondInfo()
            break
          case '4': 
            this.targetScreenNm = 'MSPPM957D' 
            break
          case '5': 
            this.targetScreenNm = 'MSPPM958D'
            // 검색으로 조회할 경우
            if (fahrTrnId === 'S3') this.$refs.MSPPM958D.fn_SetCondInfo()
            break
          case '6': 
            this.targetScreenNm = 'MSPPM959D'
            break
          default:
            break
        }
      })

    },
    
   /**
    * @description BottomSheet를 닫는다.
    */
    fn_CloseBottomSheet(){
      this.$refs.nsbottomsheet.close()
    },

   /**
    * @description 어포던스 버튼 false
    */
    fn_ctrAffordance() {
      if(this.ctrYn === true) {
        let pageNm = this.targetScreenNm
        this.$refs[pageNm].fn_ctrAffordance()
        this.ctrYn = false
      }
    },

   /**
    * @description scroll 이벤트 호출
    */
    fn_scrollDown() {
      this.$emit('scrollDown')
    }
    
 },// methods

}//export default


</script>